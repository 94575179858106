<script>
  import { Exito, Confirmacion, fetchPost } from "../../../generics";
  import { createForm } from "svelte-forms-lib";
  import * as yup from "yup";
  import {
    storeModalEditarCatalogoAreaObj,
    storeModalCatalogoAreaTitulo,
    storeModalCatalogoAreaToogle,
  } from "../../../stores";

  let data = $storeModalEditarCatalogoAreaObj;

  const { errors, form, isValid, handleChange, handleSubmit } = createForm({
    initialValues: {
      noAreaA: data.noAreaA,
      nvAreaCat: data.nvAreaCat,
    },
    validationSchema: yup.object().shape({
      nvAreaCat: yup.string().required(),
    }),
    onSubmit: (values) => {
      Confirmacion("Confirmación", "¿Desea guardar el registro?", function () {
        fetchPost(
          "https://localhost:7278/CatalogoArea/guardar",
          "text",
          values,
          function (rpta) {
            if (rpta == 1) {
              Exito("Se guardó correctamente");
              storeModalCatalogoAreaToogle.hide();
            } else {
              Error("Ocurrió un error");
            }
          }
        );
      });
    },
  });
</script>

<div
  class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
  <div class="relative w-auto my-6 mx-auto max-w-3xl">
    <!--content-->
    <div
      class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
      <!--header-->
      <div
        class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
        <h3 class="text-3xl font-semibold">
          {$storeModalCatalogoAreaTitulo} Área
        </h3>
        <button
          class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
          on:click={() => storeModalCatalogoAreaToogle.hide()}>
          <span
            class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
            ×
          </span>
        </button>
      </div>
      <!--body-->
      <div class="relative p-6 flex-auto">
        <div class="max-w-md mx-auto">
          <div class="divide-y divide-gray-200">
            <div
              class="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
              <form id="frmCatalogoArea" on:submit={handleSubmit}>
                <div class="relative mb-5 hidden">
                  <input
                    autocomplete="off"
                    name="noAreaA"
                    id="noAreaA"
                    type="text"
                    bind:value={$form.noAreaA}
                    class=" peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 border-t-0 border-x-0 text-gray-900 focus:outline-none focus:border-black"
                    placeholder="Email address" />
                  <label
                    for="noAreaA"
                    class="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                    >Id del área</label>
                </div>
                <div class="relative z-0">
                  <input
                    name="nvAreaCat"
                    id="nvAreaCat"
                    type="text"
                    bind:value={$form.nvAreaCat}
                    on:change={handleChange}
                    on:blur={handleChange}
                    class="block pt-2.5  px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
                    placeholder=" " />
                  <label
                    for="nvAreaCat"
                    class="absolute text-base text-gray-600 duration-300 transform -translate-y-6 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-gray-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >Nombre del área <span class="text-red-600">*</span></label>
                  {#if $errors.nvAreaCat}
                    <small class="text-red-600"
                      >Favor de Insertar el nombre de área</small>
                  {/if}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!--footer-->
      <div
        class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
        <button
          class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="button"
          on:click={() => storeModalCatalogoAreaToogle.hide()}>
          Cancelar
        </button>
        <button
          class="bg-emerald-500 disabled:bg-emerald-300 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="submit"
          form="frmCatalogoArea"
          disabled={!$isValid}>
          Guardar
        </button>
      </div>
    </div>
  </div>
</div>
<div class="opacity-25 fixed inset-0 z-40 bg-black" />
