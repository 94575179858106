<!-- App.svelte -->
<script>
  import { Router, Route } from "svelte-routing";
  import Loading from "./components/loading.svelte";

  // Admin Layout
  import Admin from "./layouts/Admin.svelte";
  // Auth Layout
  import Auth from "./layouts/Auth.svelte";

  // No Layout Pages
  import Index from "./views/Index.svelte";
  import Landing from "./views/Landing.svelte";
  import Profile from "./views/Profile.svelte";
  import {storeLoadingSpinner} from "./stores"

  export let url = "";

</script>

<Router {url}>
  <!-- admin layout -->
  <Route path="admin/*admin" component={Admin} />
  <!-- auth layout -->
  <Route path="auth/*auth" component={Auth} />
  <!-- no layout pages -->
  <Route path="landing" component={Landing} />
  <Route path="profile" component={Profile} />
  <Route path="/" component={Index} />
</Router>


{#if $storeLoadingSpinner == true}
<Loading></Loading>
{/if}
