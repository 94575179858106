import { writable } from "svelte/store";

export {
  storeLoadingSpinner,
  storeModalCatalogoAreaTitulo,
  storeModalCatalogoAreaToogle,
  storeModalEditarCatalogoAreaObj,
};

////////////////////////////////////////////////////////////////////////////

const createLoadingSpinner = () => {
  const { subscribe, update } = writable(false);

  return {
    subscribe,
    show: () => update((n) => (n = true)),
    hide: () => update((n) => (n = false))
  };
};

const storeLoadingSpinner = createLoadingSpinner();

////////////////////////////////////////////////////////////////////////////

const createModalEditarCatalogoAreaObj = () => {
  const { subscribe, update } = writable({});

  return {
    subscribe,
    data: (obj) => update((n) => (n = obj)),
  };
};

const storeModalEditarCatalogoAreaObj = createModalEditarCatalogoAreaObj();

////////////////////////////////////////////////////////////////////////////

const createModalCatalogoAreaTitulo = () => {
  const { subscribe, update } = writable("Agregar");

  return {
    subscribe,
    titulo: (string) => update((n) => (n = string)),
  };
};

const storeModalCatalogoAreaTitulo = createModalCatalogoAreaTitulo();

////////////////////////////////////////////////////////////////////////////

const createModalCatalogoAreaToogle = () => {
  const { subscribe, update } = writable(false);

  return {
    subscribe,
    show: () => update((n) => (n = true)),
    hide: () => update((n) => (n = false))
  };
};

const storeModalCatalogoAreaToogle = createModalCatalogoAreaToogle();
