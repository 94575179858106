<script>
  import { onMount } from "svelte";
  import {
    Exito,
    Confirmacion,
    fetchGet,
    fetchDelete,
  } from "../../../generics";
  import {
    storeModalEditarCatalogoAreaObj,
    storeModalCatalogoAreaTitulo,
    storeModalCatalogoAreaToogle,
  } from "../../../stores";

  // LISTAR
  let cadaCatalogoArea;
  onMount(async () => {
    cadaCatalogoArea = await fetchGet(
      "https://localhost:7278/CatalogoArea/listarCompleto"
    );
    console.log(cadaCatalogoArea);
  });

  //EDITAR
  async function editar(id) {
    let data = await fetchGet(
      "https://localhost:7278/CatalogoArea/recuperar?id=" + id
    );
    storeModalEditarCatalogoAreaObj.data(data);
    storeModalCatalogoAreaTitulo.titulo("Editar");
    storeModalCatalogoAreaToogle.show();
  }

  //BORRAR
  function borrar(id, nombre) {
    Confirmacion(
      "Confirmación",
      "¿Desea borrar el registro " + nombre + "?",
      function () {
        fetchDelete(
          "https://localhost:7278/CatalogoArea/borrar?id=" + id,
          "text",
          function (rpta) {
            if (rpta == 1) {
              Exito("Se borró correctamente");
              toogleModal;
            } else {
              Error("Ocurrio un error");
            }
          }
        );
      }
    );
  }
</script>

<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
  <div class="rounded-t mb-0 px-4 py-3 border-0">
    <div class="flex flex-wrap items-center">
      <div class="relative w-full px-4 max-w-full flex-grow flex-1">
        <h3 class="font-semibold text-lg text-blueGray-700">Areas</h3>
      </div>
    </div>
  </div>
  <div class="block w-full overflow-x-auto">
    <!-- Projects table -->
    <table
      class="items-center w-full bg-transparent border-collapse table-auto">
      <thead class="shadow-lg">
        <tr>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
            Nombre
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-right bg-blueGray-50 text-blueGray-500 border-blueGray-100">
            Operaciones
          </th>
        </tr>
      </thead>
      <tbody>
        {#if cadaCatalogoArea}
          {#each cadaCatalogoArea as { nvAreaCat, noAreaA }}
            <tr class="border-b-2">
              <td
                class="border-t-0  px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                <span class="ml-3 text-lg font-bold text-blueGray-500">
                  {nvAreaCat}
                </span>
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                <button
                  on:click={() => editar(noAreaA)}
                  class="text-white mr-1 text-base p-3 text-center inline-flex items-center justify-center w-12 h-12 rounded-full shadow hover:shadow-lg bg-orange-400 outline-none focus:outline-none">
                  <i class="fas fa-edit" />
                </button>
                <button
                  on:click={() => borrar(noAreaA, nvAreaCat)}
                  class="text-white text-base p-3 text-center inline-flex items-center justify-center w-12 h-12 rounded-full shadow hover:shadow-lg bg-red-500 outline-none focus:outline-none">
                  <i class="fas fa-trash" />
                </button>
              </td>
            </tr>
          {/each}
        {:else}
          <p>loading.....</p>
        {/if}
      </tbody>
    </table>
  </div>
</div>
