<script>
  // core components
  import Table_CatalogoArea from "./Table_CatalogoArea.svelte";
  import ModalAgregar_CatalogoArea from "./ModalAgregar_CatalogoArea.svelte";
  import {
    storeModalEditarCatalogoAreaObj,
    storeModalCatalogoAreaTitulo,
    storeModalCatalogoAreaToogle,
  } from "../../../stores";
  export let location;

  console.log($storeModalCatalogoAreaToogle, $storeModalCatalogoAreaTitulo, $storeModalEditarCatalogoAreaObj);
  const agregar = () => {
    storeModalCatalogoAreaToogle.show();
    storeModalEditarCatalogoAreaObj.data({});
    storeModalCatalogoAreaTitulo.titulo("Agregar");
    console.log($storeModalCatalogoAreaToogle, $storeModalCatalogoAreaTitulo, $storeModalEditarCatalogoAreaObj);
  };
</script>

<div class="flex flex-wrap mt-4">
  <div class="w-full mb-3 z-50 text-center">
    <button
      on:click={() => agregar()}
      class="bg-white text-blueGray active:bg-red-300 font-bold uppercase text-base px-6 py-3 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 "
      type="button">
      <i class="fas fa-plus" /> Agregar
    </button>
  </div>
  <div class="w-full mb-12 px-4">
    <Table_CatalogoArea />
  </div>
</div>

{#if $storeModalCatalogoAreaToogle}
  <ModalAgregar_CatalogoArea />
{/if}
