import Swal from "sweetalert2";
import { storeLoadingSpinner } from "./stores";

export{Exito, Confirmacion, fetchDelete, fetchGet, fetchPost}

function Exito(titulo = "Se guardo correctamente") {
  Swal.fire({
    icon: "success",
    title: titulo,
    showConfirmButton: false,
    timer: 1500,
  });
}

async function fetchGet(url) {
    const response = await fetch(url, {
      method: "GET",
    });
    const data = await response.json();
    return data;
  }


async function fetchDelete(url, tiporespuesta, callback) {
    try {
      storeLoadingSpinner.show();
      var res = await fetch(url, {
        method: "DELETE",
      });
      if (tiporespuesta == "json") res = await res.json();
      else if (tiporespuesta == "text") res = await res.text();
      else if (tiporespuesta == "blob") res = await res.blob();
      callback(res);
      storeLoadingSpinner.hide();
    } catch (e) {
      console.log(e);
      alert("Ocurrion un error");
      storeLoadingSpinner.hide();
    }
  }

  async function fetchPost(url, tiporespuesta, frm, callback) {
    try {
      storeLoadingSpinner.show();
      var res = await fetch(url, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(frm),
      });
      if (tiporespuesta == "json") res = await res.json();
      else if (tiporespuesta == "text") res = await res.text();
      else if (tiporespuesta == "blob") res = await res.blob();
      callback(res);
      storeLoadingSpinner.hide();
    } catch (e) {
      console.log(e);
      alert("Ocurrion un error");
      storeLoadingSpinner.hide();
    }
  }

  function Confirmacion(
    titulo = "Confirmación",
    texto = "Desea guardar los cambios?",
    callback
  ) {
    return Swal.fire({
      title: titulo,
      text: texto,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#10b981",
      cancelButtonColor: "#EF4444",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      focusConfirm: false,
      focusCancel: false,
      buttonsStyling: false,
      customClass: {
        denyButton: "hidden",
        confirmButton:
          "bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150",
        cancelButton:
          "text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        callback();
      }
    });
  }